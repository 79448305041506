<template>
    <v-container>
      <v-row>
        <v-col cols="12" md="3">
          <v-tabs vertical v-model="tab">
            <v-tab>Цена</v-tab>
            
          </v-tabs>
        </v-col>
        <v-col cols="12" md="9">
          <v-tabs-items v-model="tab">
            <v-tab-item>
              <price-control />
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
    </v-container>
  </template>
  
  <script>
  import PriceControl from "@/components/ExternalScreenPage/Price/PriceControl.vue";
  export default {
    components: { PriceControl },
    data() {
      return {
        tab: null,
      };
    },
  };
  </script>
  
  <style></style>
  