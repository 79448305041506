<template>
    <v-card flat outlined class="px-5 pt-5  ">

        <v-btn class="on-border-right remove-btn" icon density="comfortable" @click="remove"><v-icon class="remove-icon" size="30">mdi-close</v-icon></v-btn>

        <v-text-field :rules="[(val) => !!val || 'Неккоректный код']" outlined label="Код таксы"
            v-model="internalValue.code" />
        <v-text-field :rules="[priceRule]" outlined label="Сумма таксы" v-model="internalValue.amount" prefix="₽" />
    </v-card>
</template>

<script>

export default {
    name: "Tax",
    model: {
        prop: "value",
        event: "update:value",
    },
    props: {
        value: {
            type: Object,
            required: true,
        },
        priceRule: {
            type: Function,
            default: (val) => !!val || "Неккоректное число"
        },
        index:{
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            internalValue: {}
        };
    },
    async mounted() {
        this.internalValue = this.value;
    },
    methods: {
        remove() {
            this.$emit('remove', this.index)
        },
    },
    watch: {
        internalValue(val) {
            this.$emit("update:value", val);
        },
        value(val) {
            this.internalValue = val;
        },
    },
};
</script>

<style scoped>
.on-border-right {
    position: absolute;
    top: -19px;
    right: -18px;
}

.remove-btn:hover {
    .remove-icon{
        cursor: pointer;
        color: orange;
    }
}
</style>