<template>
    <v-row justify="space-between" class="pt-3 pb-3">
        <v-col cols="12" md="3">
            <v-btn large dark color="orange" width="100%" @click="clear">
                Очистить
            </v-btn>
        </v-col>
        <v-col cols="12" md="6">
            <slot></slot>
        </v-col>
        <v-col cols="12" md="3">
            <v-btn :disabled="!formValid" large dark :color="saveColor" :loading="isSave" width="100%" @click="save">
                 {{ saveText }}
            </v-btn>
        </v-col>
    </v-row>
</template>

<script>

export default {
    name: "ControlPanel",
    props:{
        formValid:{
            type: Boolean,
            default: false
        },
        saveText:{
            type: String,
            default: 'Сохранить'
        },
        saveColor:{
            type: String,
            default: 'green'
        },
        isSave:{
            type: Boolean,
            default: false
        }
    },
    methods: {
        save() {
            this.$emit("save");
        },
        clear() {
            this.$emit("clear");
        }
    },
};
</script>

<style>
.wrap {
    width: 100%;
    height: 400px;
    padding: 0;
    overflow: hidden;
}

.frame {
    height: 800px;
    width: 200%;
    -ms-transform: scale(0.5);
    -moz-transform: scale(0.5);
    -o-transform: scale(0.5);
    -webkit-transform: scale(0.5);
    transform: scale(0.5);

    -ms-transform-origin: 0 0;
    -moz-transform-origin: 0 0;
    -o-transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
}
</style>