<template>
    <div class="row">
        <div class="col-4">
            <v-text-field :rules="[(val) => isNumberValid(val) || 'Неккоректное число']" outlined label="Тариф"
                v-model="internalValue.tariff" />
        </div>
        <div class="col-4">
            <v-text-field :rules="[(val) => isNumberValid(val) || 'Неккоректное число']" outlined label="Таксы"
                v-model="internalValue.taxes" />
        </div>
        <div class="col-4">
            <v-text-field :rules="[(val) => isNumberValid(val) || 'Неккоректное число']" outlined label="Сбор"
                v-model="internalValue.marcup" />
        </div>
    </div>
</template>

<script>

import SegmentPlaceForm from "@/components/ExternalScreenPage/Price/SegmentPlaceForm.vue";
export default {
    name: "PaymentForm",
    components: { SegmentPlaceForm },
    model: {
        prop: "value",
        event: "update:value",
    },
    props: {
        value: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            internalValue: {},
        };
    },
    async mounted() {
        this.internalValue = this.value;
    },
    methods: {
        isNumberValid(val) {
            return !!val && !isNaN(Number(val));
        },
    },
    watch: {
        internalValue(val) {
            this.$emit("update:value", val);
        },
        value(val) {
            this.internalValue = val;
        },
    },
};
</script>

<style scoped></style>