<template>
    <v-card flat class="px-5 py-3">
        <panel-header>Рейс</panel-header>
        <v-row class="pt-2">

            <v-col col="12" md="12" class="text-h5">
                Пассажир
            </v-col>
            <v-col col="12" md="12">
                <passenger-form v-model="value.passenger" />
            </v-col>
        </v-row>
        <v-row>
            <v-col col="12" md="12" class="text-h5">
                Данные полета
            </v-col>
            <v-col col="12" md="12">
                <segment-form v-model="value.segment" />
            </v-col>
        </v-row>
        <v-row>
            <v-col col="12" md="12" class="text-h5">
                Информация об оплате
            </v-col>
            <v-col col="12" md="12">
                <payment-form v-model="value.payment" />
            </v-col>
        </v-row>
        <control-panel :form-valid="isFormValid" :save-text="saveText" :is-save="isSave"
            :save-color="timeDifference > 0 ? 'orange' : 'green'" @clear="clear" @save="save" />
    </v-card>
</template>

<script>
import ControlPanel from "@/components/ExternalScreenPage/ControlPanel.vue";
import PanelHeader from "@/components/ExternalScreenPage/PanelHeader.vue";
import Tax from "@/components/ExternalScreenPage/Price/Tax.vue";
import PassengerForm from "@/components/ExternalScreenPage/Price/PassengerForm.vue";
import SegmentForm from "@/components/ExternalScreenPage/Price/SegmentForm.vue";
import PaymentForm from "@/components/ExternalScreenPage/Price/PaymentForm.vue";
import TaxAddBtn from "@/components/ExternalScreenPage/Price/TaxAddBtn.vue";
import DateFormat from "@/mixins/DateFormat";

export default {
    name: "PriceControl",
    components: { PaymentForm, SegmentForm, PassengerForm, ControlPanel, PanelHeader, Tax, TaxAddBtn },
    mixins: [DateFormat],
    data() {
        return {
            value: {
                passenger: {},
                segment: {
                    from: {
                        place: '',
                        date: null
                    },
                    to: {
                        place: '',
                        date: null
                    }
                },
                payment: {}
            },
            type: 0,
            showFor: new Date(),
            isSave: false,
            currentDate: new Date(),
            intervalId: null
        };
    },
    methods: {
        clear() {
            this.value = {
                passenger: {
                    birthday: null
                },
                segment: {
                    from: {
                        place: '',
                        date: null
                    },
                    to: {
                        place: '',
                        date: null
                    }
                },
                payment: {}
            }
        },

        isNumberValid(val) {
            return !!val && !isNaN(Number(val));
        },
        async save() {
            this.isSave = true;
            // Сервер получает даты как UTC
            let commandClone = {
                passenger: {
                    ...this.value.passenger,
                    birthday: this.removeGMT(this.value.passenger.birthday)
                },
                segment: {
                    from: {
                        ...this.value.segment.from,
                        date: this.removeGMT(this.value.segment.from.date)
                    },
                    to: {
                        ...this.value.segment.to,
                        date: this.removeGMT(this.value.segment.to.date)
                    }
                },
                payment: {
                    tariff: Number(this.value.payment.tariff),
                    taxes: Number(this.value.payment.taxes),
                    marcup: Number(this.value.payment.marcup)
                }
            };

            const command = {
                data: JSON.stringify(commandClone),
                type: this.type,
                duration: 10
            }
            let resp = await this.$axios.post(this.$enums.Endpoints.Other.Screen.Set, command);
            if (!resp.data) {
                this.isSave = false;
                return;
            }
            this.updateForm(resp.data);
            this.$store.state.snackbar = {
                show: true,
                message: 'Цена обновлена',
                color: "green",
                timeout: 5000,
                contentClass: 'text-center text-h5',
            };
            this.isSave = false;
        },
        updateForm(data) {
            this.showFor = new Date(data.activeTo);
            if (!data.dataJson) return;

            const model = JSON.parse(data.dataJson);
            if (!this.responseIsValid(model)) return;
            this.value = {
                passenger: { ...model.passenger, birthday: new Date(model.passenger.birthday) },
                segment: {
                    from: {
                        place: model.segment.from.place,
                        date: this.addGMT(new Date(model.segment.from.date))
                    },
                    to: {
                        place: model.segment.to.place,
                        date: this.addGMT(new Date(model.segment.to.date))
                    }
                },
                payment: { ...model.payment }
            }
        },
        responseIsValid(data) {
            const passenger = !!data.passenger.name && !!data.passenger.gender && !!data.passenger.birthday && !!data.passenger.document;
            const segment = !!data.segment.to.place && !!data.segment.to.date && !!data.segment.from.place && !!data.segment.from.date;
            const payment = !!data.payment.tariff && !!data.payment.taxes && !!data.payment.marcup;
            return passenger && segment && payment;
        }
    },
    async mounted() {
        let resp = await this.$axios.get(this.$enums.Endpoints.Other.Screen.Get);
        if (!resp || !resp.data || this.type != resp.data.dataType)
            return;
        this.updateForm(resp.data)
        this.intervalId = setInterval(() => {
            this.currentDate = new Date();
        }, 500)
    },
    unmounted() {
        clearInterval(this.intervalId)
    },
    computed: {
        isPassValid() {
            const val = this.value.passenger;
            return !!val
                && !!val.name
                && !!val.gender
                && !!val.birthday
                && !!val.document
        },
        isSegmentValid() {
            const val = this.value.segment;
            return !!val
                && !!val.from
                && !!val.from.date
                && !!val.from.place
                && !!val.to
                && !!val.to.date
                && !!val.to.place
        },
        isPaymentValid() {
            const val = this.value.payment;
            return !!val
                && !!val.tariff
                && !!val.taxes
                && !!val.marcup
        },
        isFormValid() {
            return !!this.value && this.isPassValid && this.isSegmentValid && this.isPaymentValid
        },
        timeDifference() {
            return this.showFor - this.currentDate;
        },
        saveText() {
            return this.timeDifference > 0 ? "Закроется через " + Math.round(this.timeDifference / 1000) : 'Сохранить и показать'
        },
    },
};
</script>

<style>
.wrap {
    width: 100%;
    height: 400px;
    padding: 0;
    overflow: hidden;
}

.frame {
    height: 800px;
    width: 200%;
    -ms-transform: scale(0.5);
    -moz-transform: scale(0.5);
    -o-transform: scale(0.5);
    -webkit-transform: scale(0.5);
    transform: scale(0.5);

    -ms-transform-origin: 0 0;
    -moz-transform-origin: 0 0;
    -o-transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
}
</style>