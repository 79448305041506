<template>
    <div class="row">
        <div class="col-6">
            <segment-place-form v-model="internalValue.from" />
        </div>
        <div class="col-6">
            <segment-place-form v-model="internalValue.to" destination />
        </div>
    </div>
</template>

<script>

import SegmentPlaceForm from "@/components/ExternalScreenPage/Price/SegmentPlaceForm.vue";
export default {
    name: "SegmentForm",
    components: { SegmentPlaceForm },
    model: {
        prop: "value",
        event: "update:value",
    },
    props: {
        value: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            internalValue: {
                from: {
                    place: '',
                    date: null
                },
                to: {
                    place: '',
                    date: null
                }
            },
        };
    },
    async mounted() {
        this.internalValue = this.value;
    },
    watch: {
        internalValue(val) {
            this.$emit("update:value", val);
        },
        value(val) {
            this.internalValue = val;
        },
    },
};
</script>

<style scoped></style>