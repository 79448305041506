<template>
    <v-row class="row" no-gutters>
        <v-col cols="7" class="pr-2">
            <v-text-field :rules="[(val) => !!val || 'Неккоректное имя']" outlined label="ФИО"
                v-model="internalValue.name" />
        </v-col>
        <v-col cols="5" class="pl-2">
            <v-text-field :rules="[(val) => !!val || 'Неккоректный документ']" outlined label="Документ"
                v-model="internalValue.document" />
        </v-col>
        <v-col cols="4" class="pr-2">
            <custom-date-picker :max="new Date()" format="dd.MM.yyyy" v-model="internalValue.birthday" mask="##.##.####"
                no-title outlined text="Дата рождения" />
        </v-col>
        <v-col cols="4" class="px-2">
            <v-select v-model="internalValue.gender" label="Пол" :items="genders" outlined></v-select>
        </v-col>
        <v-col cols="4" class="pl-2">
            <v-text-field v-model="internalValue.phone" outlined label="Телефон" />
        </v-col>
    </v-row>
</template>

<script>

import CustomDatePicker from "@/components/UI/CustomDatePicker.vue";
export default {
    name: "PassengerForm",
    components: { CustomDatePicker },
    model: {
        prop: "value",
        event: "update:value",
    },
    props: {
        value: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            internalValue: {},
            genders: [
                {
                    text: 'Мужчина',
                    value: 'М'
                },
                {
                    text: 'Женщина',
                    value: 'Ж'
                },
            ]
        };
    },
    async mounted() {
        this.internalValue = this.value;
    },
    watch: {
        internalValue(val) {
            this.$emit("update:value", val);
        },
        value(val) {
            if (!val || !val.birthday)
                this.internalValue.birthday = null
            this.internalValue = val;
        },
    },
};
</script>

<style scoped></style>